import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/generalSlides/wieczor-uwielbienia-19-01-2020.jpg'} alt={'wieczor uwielbienia 19 01 2020'} style={{ padding: '30px', margin: '5vw auto', width: '100%' }} />
            <div>
              {/* <h3 className="miod"><strong style={miod}>Kolejne spotkanie w ramach Diecezjalnej Szkoły Animatora odbędzie się 25.01.2020 w Opolu. </strong> </h3> */}
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
